:root {
    --brand-primary            : #000;
    --brand-primary-lighten-10 : #1a1a1a;
    --brand-primary-lighten-40 : #666666;
    --brand-primary-darken-5   : black;
    --brand-primary-darken-10  : black;
    --brand-primary-darken-12  : black;
    --brand-primary-opacity-25 : #00000040;
    --brand-primary-opacity-50 : #00000080;

    --brand-secondary          : #08a5da;

    --highlight                : #e2e2e2;
    --highlight-tint           : #f7f7f7;

    --navbar-height            : 60px;
    --navbar-brand-width       : 214px;
    --navbar-brand-height      : 32px;
    --navbar-brand-margin-left : 10px;
    --navbar-brand-logo-size   : auto;
    --navbar-brand-logo        : url('/assets/images/clients/subaru/navbar-brand-logo.png');
    --navbar-brand-logo-mobile : url('/assets/images/clients/subaru/navbar-brand-logo.png');

    --logo-gap                 : 24px;
    --logo-bg-size             : auto;
    --login-logo-height        : auto;
    --login-logo-width         : 200px;
    --login-logo               : url('/assets/images/PoweredByShift.png');
    --login-brand-logo         : url('/assets/images/clients/subaru/Login_Logo.png');
    --login-brand-logo-width   : 250px;
    --login-brand-logo-height  : 145px;

    --sidebar-width            : 250px;
    --mobile-sidebar-width     : 250px;

    --sidebar-bg               : #000;
    --sidebar-bg-darken-3      : black;
    --sidebar-bg-darken-5      : black;
    --sidebar-bg-darken-10     : black;
    --sidebar-bg-lighten-5     : #0d0d0d;

    --combined-bar-green       : #18b52d;
    --combined-bar-blue        : #224197;
    --combined-bar-yellow      : #f9bb11;
    --combined-bar-red         : #c51230;
}